import { Switch, Match } from "solid-js";
import type { MainProps } from "./_model";
import { global } from ":global";

export default function AuthLayout(props: MainProps) {
  const $breakpoint = global.store.hooks.dom.useBreakpoints();
  return (
    <main class={`flex flex-col w-full h-full`}>
      <Switch fallback="awaiting breakpoint layout...">
        {/* desktop view */}
        <Match when={$breakpoint()?.misc.sm.widthIsGreaterOrEquals}>{props.children}</Match>
        {/* phone view */}
        <Match when={$breakpoint()?.misc.sm.widthIsLess}>{props.children}</Match>
      </Switch>
    </main>
  );
}
